import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-2643fd52"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  class: "caodi"
};
const _hoisted_2 = {
  key: 0,
  class: "card"
};
const _hoisted_3 = {
  class: "img"
};
const _hoisted_4 = ["src"];
const _hoisted_5 = {
  class: "name"
};
const _hoisted_6 = {
  class: "jian"
};
const _hoisted_7 = {
  class: "zeng"
};
const _hoisted_8 = {
  class: "zeng"
};
const _hoisted_9 = {
  class: "zeng"
};
const _hoisted_10 = {
  class: "zeng"
};
const _hoisted_11 = {
  class: "price"
};
const _hoisted_12 = {
  class: "shu"
};
const _hoisted_13 = {
  class: "tit"
};
const _hoisted_14 = ["title"];
const _hoisted_15 = {
  class: "center_tit"
};
const _hoisted_16 = {
  class: "center_price"
};
const _hoisted_17 = {
  class: "left_text"
};
const _hoisted_18 = {
  class: "left_text"
};
const _hoisted_19 = {
  class: "left_text"
};
const _hoisted_20 = {
  class: "left_text"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon");
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_van_list = _resolveComponent("van-list");
  const _component_Footer = _resolveComponent("Footer");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_van_nav_bar, {
    title: $data.lang.u1,
    onClickLeft: $options.back,
    onClickRight: $options.onClickLeft
  }, {
    left: _withCtx(() => [_createVNode(_component_van_icon, {
      name: "arrow-left"
    })]),
    right: _withCtx(() => [_createVNode(_component_van_icon, {
      name: "chat-o",
      size: "18"
    })]),
    _: 1
  }, 8, ["title", "onClickLeft", "onClickRight"]), _createElementVNode("div", _hoisted_1, [$data.item.logo != undefined ? (_openBlock(), _createElementBlock("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
    src: $data.app_root + $data.item.logo
  }, null, 8, _hoisted_4)]), _createElementVNode("div", _hoisted_5, _toDisplayString($data.item.title), 1), _createElementVNode("div", _hoisted_6, _toDisplayString($data.item.des), 1), _createElementVNode("div", _hoisted_7, _toDisplayString($data.lang.t1) + " : " + _toDisplayString($data.item.create_time), 1), _createElementVNode("div", _hoisted_8, _toDisplayString($data.lang.t13) + " : " + _toDisplayString($data.item.ci), 1), _createElementVNode("div", _hoisted_9, _toDisplayString($data.lang.t14) + " : " + _toDisplayString($data.item.zong) + "HOC", 1), _createElementVNode("div", _hoisted_10, [_createTextVNode(_toDisplayString($data.lang.t5) + " : " + _toDisplayString($data.item.chan) + " HOC ", 1), _createVNode(_component_van_icon, {
    name: "question-o",
    onClick: _cache[0] || (_cache[0] = $event => $options.qust())
  })]), _createElementVNode("div", _hoisted_11, _toDisplayString($data.item.price) + "USDT", 1), _createElementVNode("div", _hoisted_12, [_createElementVNode("span", {
    style: _normalizeStyle('background-color:' + $data.item.color)
  }, _toDisplayString($data.item.shu), 5)])])) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_13, _toDisplayString($data.lang.t7), 1), _createVNode(_component_van_list, {
    loading: $data.loading,
    "onUpdate:loading": _cache[1] || (_cache[1] = $event => $data.loading = $event),
    finished: $data.finished,
    "finished-text": "No more",
    onLoad: $options.onLoad
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.list, item => {
      return _openBlock(), _createElementBlock("div", {
        key: item,
        title: item,
        class: "list_card"
      }, [_createElementVNode("div", _hoisted_15, _toDisplayString($data.lang.t12), 1), _createElementVNode("div", _hoisted_16, _toDisplayString(item.num), 1), _createElementVNode("div", _hoisted_17, [_createElementVNode("span", null, _toDisplayString($data.lang.t8) + "：", 1), _createTextVNode(" " + _toDisplayString(item.create_time), 1)]), _createElementVNode("div", _hoisted_18, [_createElementVNode("span", null, _toDisplayString($data.lang.t9) + "：", 1), _createTextVNode(" " + _toDisplayString(item.title), 1)]), _createElementVNode("div", _hoisted_19, [_createElementVNode("span", null, _toDisplayString($data.lang.t10) + "：", 1), _createTextVNode(" " + _toDisplayString(item.chan_time), 1)]), _createElementVNode("div", _hoisted_20, [_createElementVNode("span", null, _toDisplayString($data.lang.t11) + "：", 1), _createTextVNode(" " + _toDisplayString(item.zt), 1)])], 8, _hoisted_14);
    }), 128))]),
    _: 1
  }, 8, ["loading", "finished", "onLoad"]), _createVNode(_component_Footer, {
    lang_list: $data.lang_list,
    lang: $data.lang
  }, null, 8, ["lang_list", "lang"])])]);
}